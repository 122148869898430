import {defineStore} from 'pinia'
import func from "../../../helpers/func";
import {useMenuStore} from "../menu";

let menuStore = useMenuStore()
export const useMedia = defineStore('media', {
    state: () => ({
        _media: [], unicId: '', activeCategory: [], search: '', _currentPage: 1
    }), getters: {
        media(state) {
            return state._media
        }, currentPage(state) {
            return state._currentPage
        },

    }, actions: {
        async getMedia(joinData = false) {
            let {data} = await func.postData('main/getDataList', {
                searchText: this.search,
                componentUnicId: this.unicId,
                taxonomies: this.activeCategory?.[0] !== 0 ? {
                    media_category: this.activeCategory
                } : undefined,
                id: menuStore?.indx?.menus?.find(item => item.url_slug === 'media')?.id,
                pageNumber: this.currentPage,
            })
            joinData ? this._media = [...this._media, ...data?.list] : this._media = data?.list

            setTimeout(() => {
                menuStore.triggerUpdate = !menuStore.triggerUpdate;
            }, 300)
            return data?.list

        }, setMedia(media) {
            this._media = media
        }, setCurrenPage(page) {
            this._currentPage = page
        }, setUnicId(id) {
            this.unicId = id
        }, setActiveCategory(category) {
            this.activeCategory = category
            this.getMedia()
        }, setSearchText(search) {
            this.search = search
            this.getMedia()
        }
    },
})
