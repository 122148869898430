<template>
    <div @mouseenter="menuStore.getComponentCursor($event, null)" class="media-inner">
        <div class="media-filter-container">
            <div class="search-inner box">
                <input type="text"
                       @input="searchMedia"
                       v-model="search"
                       :placeholder="$fn.tr('Search for the media')" class="input">
                <img src="../../../assets/icons/search.png" class="search-icon" alt="">
            </div>
            <div class="select-inner box">
                <el-select
                    v-model="activeCategory" @change="setActiveCategory">
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <template v-if="media.length">
            <MediaInnerItem v-for="(item,index) in media"
                            :key="item.id"
                            :item="item"
                            :odd="index%2===0"/>
        </template>
        <div v-else class="no-media">
            <h2 class="title luxury">{{ $fn.tr('There is no media') }}</h2>
        </div>
    </div>
</template>

<script setup>
import MediaInnerItem from './parts/MediaInner'
import {computed, onMounted, ref} from "vue";
import {defineProps} from "vue";
import {useMedia} from '../../../store/pinia/media/index'
import { handleResize } from "@/composables/resizeHandler"
import {
    ElSelect, ElOption,
} from 'element-plus'
import {useMenuStore} from "@/store/pinia/menu/index";
import fn from "../../../helpers/func";

const { windowWidth } = handleResize()

let mediaStore = useMedia()
let menuStore = useMenuStore()
const props = defineProps({
    contentData: {
        type: Object,
    },
});
let media = computed(() => mediaStore.media || {})
let search = ref('')
const setMedia = () => {
    let media = props.contentData?.data?.list
    mediaStore.setMedia(media)
}
const setActiveCategory = async (e) => {
    mediaStore.setCurrenPage(1)
    mediaStore.setActiveCategory([e])
}
const searchMedia = () => {
    mediaStore.setCurrenPage(1)
    mediaStore.setSearchText(search.value)
}
let activeCategory = ref('')
let categories = computed(() => {
    return [
        {
            title: fn.tr('All Categories'),
            id: 0
        },
        ...menuStore.indx?.terms?.media_category,
    ]
})
let currentPage = computed(() => mediaStore.currentPage)
let scroller = menuStore.Loco
let disableScrollEvent = ref(false)

const addMoreMediaOnScroll = async () => {
    disableScrollEvent.value = true
    mediaStore.setCurrenPage(currentPage.value + 1)
    let data = await mediaStore.getMedia(true)
    setTimeout(() => {
        menuStore.triggerUpdate = !menuStore.triggerUpdate;
        if (data.length !== 0) disableScrollEvent.value = false
    }, 300)
}
const scrollEvent = () => {
    if (windowWidth.value >= 1024) {
        scroller?.on("scroll", (e) => {
            if (disableScrollEvent.value) return
            let maxHeight = e.limit.y
            let currentY = e.scroll.y
            let alfa = currentY / maxHeight
    
            if (alfa > .75) {
                addMoreMediaOnScroll()
            }
        })
    } else {
        const appParent = document.querySelector("#app");
        appParent.addEventListener("scroll", (e) => {
            if (disableScrollEvent.value) return
            let maxHeight = e.target.scrollHeight
            let currentY = e.target.scrollTop
            let alfa = currentY / maxHeight
    
            if (alfa > .75) {
                addMoreMediaOnScroll()
            }
        });
    }
}

onMounted(() => {
    setMedia()
    mediaStore.setUnicId(props.contentData?.conf?.unicId)
    activeCategory.value = categories.value?.find(item => item.id === 0)?.id
    scrollEvent()
})
</script>

<style lang="scss" scoped>
.media-inner {
    padding: 0 203px 133px;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 0 170px 133px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        padding: 0 144px 100px;
    }
    @media only screen and (max-width: 1023px) {
        width: 90%;
        padding: 0 0 89px 0;
        margin: 0 auto;
    }

    .media-filter-container {
        position: relative;
        z-index: 9;
        transform: translateY(-50%);
        width: 70%;
        margin: 0 auto;
        background: $primaryNavy;
        padding: 0 34px;
        display: flex;
        align-items: center;
        color: white;
        font-family: 'FiraGO';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        @media only screen and (max-width: 1023px) {
            width: 100%;
            padding: 0 10px;
        }

        .box {
            width: 50%;
            display: flex;
            align-items: center;
        }

        .search-inner {
            padding-right: 46px;
            border-right: 1px solid $textRed;
            @media only screen and (max-width: 1023px) {
                padding-right: 15px;
            }

            .input {
                cursor: pointer;
                padding: 18px 0;
                padding-right: 15px;
                font-family: 'FiraGO';
                font-weight: 400;
                font-size: 16px;
                height: 100%;
                width: 100%;
                background: transparent;
                border: none;
                color: white;

                &:focus::placeholder {
                    color: transparent;
                }

                &::placeholder {
                    opacity: 1;
                    color: white;
                    transition: all .25s ease-out;
                }
            }
        }

        .select-inner:deep {
            padding-left: 35px;
            @media only screen and (max-width: 1023px) {
                padding-left: 15px;
            }

            .el-select {
                width: 100%;

                .el-input {

                    .el-input__wrapper {
                        background: transparent;
                        box-shadow: none !important;
                        @media only screen and (max-width: 1023px) {
                            padding: 0;
                        }

                        .el-input__inner {
                            font-family: 'FiraGO';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            color: white;
                        }

                        .el-select__caret {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .no-media {
        width: 100%;
        padding: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list-move, /* apply transition to moving elements */
    .list-enter-active,
    .list-leave-active {
        transition: all 1s cubic-bezier(.23, -0.01, .02, .98);
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
    }

    /* ensure leaving items are taken out of layout flow so that moving
       animations can be calculated correctly. */
    .list-leave-active {
        position: absolute;
    }
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .no-media {
            .title {
                color: white;
            }
        }
    }
}

</style>


<style lang="scss">
.el-popper {
    .el-select-dropdown__item {
        span {
            font-family: FiraGO;
            font-weight: 400;
            font-size: 16px;
        }
    }
}
</style>
