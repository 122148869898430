<template>
    <div :class="{odd:odd}" @click="changeUrl" class="media-inner-item">
        <div class="left">
            <span class="date fira">{{ mediaItem.date }}</span>
            <h2 class="header-title luxury">{{ mediaItem.title }}</h2>
            <span class="description fira">{{ mediaItem.teaser }} </span>
            <div class="explore-container">
                <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                          fill="#940128"></path>
                </svg>
                <span class="title fira">{{ $fn.tr('Explore') }}</span>
            </div>
        </div>
        <div
            data-scroll
            data-scroll-speed="1"
            class="media-image-inner">
            <picture class="picture">
                <source
                    media="(max-width:767px)"
                    :srcset="mediaItem.mobile"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="mediaItem.tablet"
                />
                <img class="media-image"
                     :src="mediaItem.desktop"
                />
            </picture>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import moment from 'moment'
import {useRouter} from "vue-router";
import {useMenuStore} from "../../../../store/pinia/menu";

let props = defineProps({
    odd: {
        type: Boolean,
        default: true
    },
    item: {
        type: Object,
        default: true
    }
})
let store = useMenuStore()
let mediaItem = computed(() => {
    return {
        ...props.item,
        date: store.getLang === 'ge' ? moment(props.item.date).locale("ka").format('DD MMM. YYYY')
            : store.getLang === 'ru' ? moment(props.item.date).locale("ru").format('DD MMM. YYYY') : moment(props.item.date).format('DD MMM. YYYY'),
        desktop: props.item.cover_image?.[0]?.devices?.desktop,
        mobile: props.item.cover_image?.[0]?.devices?.mobile,
        tablet: props.item.cover_image?.[0]?.devices?.tablet,
    }
})
let router = useRouter()

const changeUrl = () => {
    let singlePath = '/' + store.getLang + "/media/singleview/" + props.item.id + "-" + props.item.slug;
    router.push({
        path: singlePath,
    })
}

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.23, -0.01, .02, .99);
.media-inner-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primaryNavy;
    margin-top: 89px;
    flex-direction: row-reverse;
    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse !important;
    }

    &.odd {
        flex-direction: row;
    }

    .left {
        width: 30%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-top: 21px;
        }

        .date {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: $burgundy;
            transition: all .45s ease-out;
        }

        .header-title {
            margin-top: 34px;
            font-weight: 400;
            font-size: 34px;
            text-transform: uppercase;
            transition: all .45s ease-out;
            @media only screen and (max-width: 1365px) {
                font-size: 21px;
                margin-top: 21px;
            }
        }

        .description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            margin-top: 34px;
            display: block;
            transition: all .45s ease-out;
            @media only screen and (max-width: 1365px) {
                margin-top: 21px;
            }
        }

        .explore-container {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 34px;
            @media only screen and (max-width: 1365px) {
                margin-top: 21px;
            }

            &:hover {
                svg {
                    animation: goBack .45s ease-out;
                }

                .title {
                    opacity: .6;
                }
            }

            .icon {
                margin-right: 15px;
                transform: translateX(0);
                transition: all .45s ease-out;
            }

            .title {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }
        }

    }

    .media-image-inner {
        width: 60%;
        height: 594px;
        overflow: hidden;
        cursor: pointer;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            height: 500px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 768px) {
            height: 350px;
        }
        @media only screen and (max-width: 767px) {
            height: 300px;
            width: 100%;
        }

        &:hover {
            .media-image {
                transform: scale(1.1);
            }
        }

        .media-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .45s $ease;
        }
    }

    @keyframes goBack {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0);
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .media-inner-item {
            color: white;
        }
    }
}

</style>
